import { createSelector } from '@reduxjs/toolkit';
import { selectAppSettingsData } from './appSelectors';
import { selectAvailablePickupRestaurantsList } from './restaurantSelectors';

/**
 * Returns a `{ delivery: bool, carryOut: bool }` object, telling
 * wether delivery or carry-out possible based on general and temporal
 * settings
 */
export const selectCarryOutAndDeliverySettings = createSelector(
  [selectAppSettingsData, selectAvailablePickupRestaurantsList],
  (appSettings, availableRestaurants) => {
    return {
      delivery: appSettings.DELIVERY && !appSettings.IS_DELIVERY_STOP,
      carryOut: appSettings.CARRY_OUT && availableRestaurants.length > 0,
    };
  }
);
