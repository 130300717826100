import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useSrcImage } from 'hooks';
import { useAppDispatch } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useTranslation } from 'react-i18next';

interface ProductProps {
  itemOption: number;
  onClose: () => void;
  actionId: number;
  title: string;
  description: string;
  src: string;
}

const Product: FC<ProductProps> = ({
  itemOption,
  onClose,
  actionId,
  title,
  description,
  src,
}) => {
  const dispatch = useAppDispatch();
  const { addPromotion } = orderSlice.actions;
  const image = useSrcImage(src);
  const { t } = useTranslation();

  const handleSelectProduct = () => {
    dispatch(addPromotion({ action_id: actionId, item_option_id: itemOption }));
    onClose();
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
      <Box
        sx={{ width: '110px', height: '110px' }}
        component="img"
        src={image}
      />
      <Box sx={{ flexGrow: '1' }}>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: { xs: '15px', sm: '20px' },
            lineHeight: { xs: '17px', sm: '23px' },
            marginBottom: '5px',
          }}
          variant="h6"
          color="text.main"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: '300',
            fontSize: { xs: '12px', sm: '14px' },
            lineHeight: { xs: '15px', sm: '18px' },
          }}
          color="grey.50"
        >
          {description}
        </Typography>
      </Box>

      <Button
        sx={{
          width: '170px',
          height: '50px',
          textTransform: 'inherit',
          fontSize: '18px',
          lineHeight: '21px',
          borderRadius: '6px',
          fontWeight: '300',
          color: 'primary.contrastText',
          boxShadow: 'none',
        }}
        variant="contained"
        onClick={handleSelectProduct}
      >
        {t('add_to_cart')}
      </Button>
    </Box>
  );
};

export default Product;
