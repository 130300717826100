import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectMenuProduct } from 'store/selectors/menuSelectors';

interface CardProductProps {
  itemId: number;
  categoryId: number;
}

const CardProduct: FC<CardProductProps> = ({ itemId, categoryId }) => {
  const productData = useAppSelector(selectMenuProduct(itemId, categoryId));

  return (
    <Typography
      sx={{
        fontSize: { xs: '14px', md: '17px' },
        lineHeight: { xs: '17px', md: '20px' },
      }}
    >
      {productData?.name}
    </Typography>
  );
};

export default CardProduct;
