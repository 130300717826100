import React, { FC, useCallback, useState, useMemo } from 'react';
import { Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectAccountData,
  selectBonus,
} from 'store/selectors/accountSelectors';
import { AuthModal } from 'components/modals';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import { useMediaQuery } from 'react-responsive';

const LoginButton: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  const { t } = useTranslation();
  const { clearAuthResponse } = accountSlice.actions;
  const { first_name } = useAppSelector(selectAccountData);
  const bonuses = useAppSelector<number>(selectBonus);
  const [authModal, setAuthModal] = useState<boolean>(false);
  const { BONUS_PROGRAM } = useAppSelector<{ BONUS_PROGRAM: boolean }>(
    selectAppSettingsData
  );
  const isNameHidden = useMediaQuery({ minWidth: 1199 });

  const handleOpenModal = useCallback(() => {
    setAuthModal(true);
  }, [authModal]);

  const handleCloseModal = useCallback(() => {
    setAuthModal(false);
    dispatch(clearAuthResponse());
  }, [authModal]);

  const handleNavigate = () => {
    navigate('/account');
  };

  const buttonHandler = useMemo(() => {
    if (isAuth) {
      return handleNavigate;
    }
    return handleOpenModal;
  }, [isAuth]);

  const buttonName = useMemo(() => {
    if (isAuth && first_name) {
      return first_name;
    }
    if (isAuth && !first_name) {
      return t('account');
    }
    return t('sign_in');
  }, [isAuth, first_name]);

  return (
    <>
      <Button
        sx={{
          display: { xs: 'none', md: 'flex' },
          borderRadius: '46px',
          fontSize: '16px',
          fontWeight: '600',
          border: '2px solid',
          borderColor: 'primary.main',
          color: 'primary.main',
          gap: '5px',
          '&:hover': {
            border: '2px solid',
          },
          whiteSpace: 'nowrap',
        }}
        variant="outlined"
        onClick={buttonHandler}
      >
        {isNameHidden && buttonName}
        {BONUS_PROGRAM && isAuth && bonuses ? (
          <Typography
            sx={{
              fontSize: '12px',
              lineHeight: '14px',
              fontWeight: '300',
            }}
          >
            ({bonuses} {t('bonuses')})
          </Typography>
        ) : (
          <Person color="inherit" fontSize="medium" />
        )}
      </Button>
      <AuthModal isOpen={authModal} onClose={handleCloseModal} />
    </>
  );
};

export default LoginButton;
