import React, { FC, useState, useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { DateTimeFormatOptions } from 'types/DateTimeFormat';
import Parse from 'html-react-parser';

interface ContentProps {
  src: string;
  title: string;
  description: string;
  date: string;
}

const Content: FC<ContentProps> = ({ src, title, description, date }) => {
  const options: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const [promotionDate] = useState(
    new Date(date).toLocaleString('ru', options)
  );

  const descLink = useMemo(() => {
    const pattern = /\bhttps?:\/\/\S+/gi;
    if (description) {
      return description.replace(
        pattern,
        '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );
    }
    return '';
  }, [description]);

  return (
    <>
      <Box>
        <Paper
          sx={{
            height: '300px',
            display: 'flex',
            width: '100%',
            overflow: 'hidden',
            borderRadius: '30px 30px 0 0',
            border: 'none',
          }}
          variant="outlined"
        >
          <Box
            component="img"
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              verticalAlign: 'top',
            }}
            alt="promotions preview image"
            src={src}
          />
        </Paper>
        <Box
          sx={{
            overflow: 'hidden',
            borderRadius: '0 0 30px 30px',
          }}
        >
          <Box
            sx={{
              padding: '30px',
              overflowY: 'auto',
              maxHeight: '345px',
              '&::-webkit-scrollbar': {
                width: 7,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'grey.400',
                borderRadius: '20px',
              },
            }}
          >
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '40px',
                lineHeight: '40px',
                marginBottom: '20px',
              }}
              variant="h4"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                marginBottom: { xs: '15px', sm: '20px' },
                lineHeight: '20px',
                '& a': {
                  color: 'primary.main',
                },
                whiteSpace: 'pre-wrap',
              }}
              variant="body1"
              component="pre"
            >
              {Parse(descLink) || ''}
            </Typography>
            <Typography color="grey.600" variant="body1">
              {promotionDate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Content;
