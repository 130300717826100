import { createSelector } from '@reduxjs/toolkit';
import { PAYMENT_TYPES, PaymentTypeOption } from 'types/Order';
import { RootState } from '../store';
import { selectAppSettingsData } from './appSelectors';
import { selectIsDelivery } from './orderSelectors';

const selectBrandsData = (state: RootState) => state.brand;
/**
 * Returns brands list
 */
export const selectBrands = createSelector(
  [selectBrandsData],
  (brandsReducer) =>
    brandsReducer.brands
      .filter((item) => item.is_active)
      .sort((prev, next) => prev.order_by - next.order_by)
);
/**
 * Returns is brands
 */
export const selectIsBrands = createSelector(
  [selectBrands],
  (brands) => brands.length > 1
);
/**
 * Returns selected brand id
 */
export const selectBrandId = createSelector(
  [selectBrandsData],
  (brandsData) => brandsData.brand_id
);
/**
 * Returns selected brand id
 */
export const selectBrandData = createSelector(
  [selectBrands, selectBrandId],
  (brands, brandId) => {
    return brands.find((brand) => brand.id === brandId);
  }
);
/**
 * Returns is age limit param
 */
export const selectIsAgeLimit = (id: number) =>
  createSelector(
    [selectBrands],
    (brands) => brands.find((brand) => brand.id === id)?.is_age_limit
  );
/**
 * Returns age limit text
 */
export const selectAgeLimitText = (id: number) =>
  createSelector(
    [selectBrands],
    (brands) => brands.find((brand) => brand.id === id)?.age_limit_text
  );
/**
 * Returns brand payment types
 */
export const selectBrandPaymentTypes = createSelector(
  [selectBrands, selectBrandId, selectIsDelivery],
  (brands, brandId, isDelivery) => {
    const paymentType = brands.find(
      (brand) => brand.id === brandId
    )?.payment_type;

    const paymentTypes: PaymentTypeOption[] = [];

    if (isDelivery) {
      if (paymentType?.is_payment_card) {
        paymentTypes.push({
          label: paymentType.payment_type_card_label,
          value: PAYMENT_TYPES.PAYMENT_CARD,
        });
      }

      if (paymentType?.is_payment_cash) {
        paymentTypes.push({
          label: paymentType.payment_cash_label,
          value: PAYMENT_TYPES.PAYMENT_CASH,
        });
      }

      if (paymentType?.is_payment_card_courier) {
        paymentTypes.push({
          label: paymentType.payment_card_courier_label,
          value: PAYMENT_TYPES.PAYMENT_CARD_COURIER,
        });
      }

      if (paymentType?.is_payment_on_account) {
        paymentTypes.push({
          label: paymentType.payment_type_on_account_label,
          value: PAYMENT_TYPES.PAYMENT_ON_ACCOUNT,
        });
      }

      if (paymentType?.is_payment_bonus) {
        paymentTypes.push({
          label: paymentType.payment_bonus_label,
          value: PAYMENT_TYPES.PAYMENT_BONUS,
        });
      }

      if (paymentType?.is_payment_android_pay) {
        paymentTypes.push({
          label: paymentType.payment_type_android_pay_label,
          value: PAYMENT_TYPES.PAYMENT_GOOGLE_PAY,
        });
      }

      if (paymentType?.is_payment_apple_pay) {
        paymentTypes.push({
          label: paymentType.payment_type_apple_pay_label,
          value: PAYMENT_TYPES.PAYMENT_APPLE_PAY,
        });
      }

      if (paymentType?.is_payment_sbp) {
        paymentTypes.push({
          label: paymentType.payment_type_sbp_label,
          value: PAYMENT_TYPES.PAYMENT_ON_SBP,
        });
      }

      if (paymentType?.is_payment_samsung_pay) {
        paymentTypes.push({
          label: paymentType.payment_type_samsung_pay_label,
          value: PAYMENT_TYPES.PAYMENT_SAMSUNG_PAY,
        });
      }
    } else {
      if (paymentType?.is_payment_pickup_card) {
        paymentTypes.push({
          label: paymentType.payment_type_card_label,
          value: PAYMENT_TYPES.PAYMENT_CARD,
        });
      }

      if (paymentType?.is_payment_pickup_cash) {
        paymentTypes.push({
          label: paymentType.payment_cash_label,
          value: PAYMENT_TYPES.PAYMENT_CASH,
        });
      }

      if (paymentType?.is_payment_pickup_card_courier) {
        paymentTypes.push({
          label: paymentType.payment_card_courier_label,
          value: PAYMENT_TYPES.PAYMENT_CARD_COURIER,
        });
      }

      if (paymentType?.is_payment_pickup_on_account) {
        paymentTypes.push({
          label: paymentType.payment_type_on_account_label,
          value: PAYMENT_TYPES.PAYMENT_ON_ACCOUNT,
        });
      }

      if (paymentType?.is_payment_pickup_bonus) {
        paymentTypes.push({
          label: paymentType.payment_bonus_label,
          value: PAYMENT_TYPES.PAYMENT_BONUS,
        });
      }

      if (paymentType?.is_payment_pickup_android_pay) {
        paymentTypes.push({
          label: paymentType.payment_type_android_pay_label,
          value: PAYMENT_TYPES.PAYMENT_GOOGLE_PAY,
        });
      }

      if (paymentType?.is_payment_pickup_apple_pay) {
        paymentTypes.push({
          label: paymentType.payment_type_apple_pay_label,
          value: PAYMENT_TYPES.PAYMENT_APPLE_PAY,
        });
      }

      if (paymentType?.is_payment_pickup_sbp) {
        paymentTypes.push({
          label: paymentType.payment_type_sbp_label,
          value: PAYMENT_TYPES.PAYMENT_ON_SBP,
        });
      }

      if (paymentType?.is_payment_pickup_samsung_pay) {
        paymentTypes.push({
          label: paymentType.payment_type_samsung_pay_label,
          value: PAYMENT_TYPES.PAYMENT_SAMSUNG_PAY,
        });
      }
    }

    return paymentTypes;
  }
);
/**
 * Returns logo for header
 */
export const selectLogo = createSelector(
  [selectAppSettingsData, selectBrandData],
  (settings, brand) => {
    const brandLogoLight = brand?.logo_light;
    const brandLogoDark = brand?.logo_dark;

    if (process.env.REACT_APP_HEADER_LOGO === 'dark') {
      return brandLogoLight || settings.LOGO_LIGHT;
    }
    return brandLogoDark || settings.LOGO_DARK;
  }
);
