import React, { FC, memo } from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import {
  Nav,
  HeaderLogo,
  HeaderButtons,
  HeaderTopBar,
} from 'components/generic/Header';

const Header: FC = () => {
  return (
    <>
      <AppBar
        color="inherit"
        sx={{
          position: 'fixed',
          top: '0px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <HeaderTopBar />
        <Box
          sx={{
            maxWidth: '1600px',
            width: '100%',
            padding: '0 20px',
            margin: '0 auto',
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              justifyContent: 'space-between',
              position: 'relative',
              alignItems: 'center',
              minHeight: { xs: '70px', md: '80px' },
            }}
          >
            <HeaderLogo />
            <Nav />
            <HeaderButtons />
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
};

export default memo(Header);
