import React, { FC, useRef, memo, useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPromotionsWithoutPromocode } from 'store/selectors/promotionSelectors';
import { Card } from 'components/menu/PromotionSlider';
import { useTranslation } from 'react-i18next';
import { Promotion } from 'types/Promotion';

const PromotionSlider: FC = () => {
  const [isRefs, setIsRefs] = useState<boolean>(false);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { i18n } = useTranslation();
  const promotions = useAppSelector(selectPromotionsWithoutPromocode);

  useEffect(() => {
    if (navigationPrevRef !== null && navigationNextRef !== null) {
      setIsRefs(true);
    }
  }, [navigationPrevRef, navigationNextRef]);

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton
        sx={{
          display: { xs: 'none', md: 'inline-flex' },
          marginRight: '10px',
          position: 'absolute',
          left: '-50px',
          top: '0',
          bottom: '0',
          height: '40px',
          margin: 'auto',
          '&.swiper-button-disabled': {
            opacity: 0,
            cursor: 'inherit',
          },
        }}
        size="medium"
        ref={navigationPrevRef}
      >
        {i18n.dir() ? (
          <ArrowBackIosNew fontSize="medium" color="primary" />
        ) : (
          <ArrowForwardIos fontSize="medium" color="primary" />
        )}
      </IconButton>

      <IconButton
        sx={{
          display: { xs: 'none', md: 'inline-flex' },
          marginLeft: '10px',
          position: 'absolute',
          right: '-50px',
          top: '0',
          bottom: '0',
          height: '40px',
          margin: 'auto',
          '&.swiper-button-disabled': {
            opacity: 0,
            cursor: 'inherit',
          },
        }}
        size="medium"
        ref={navigationNextRef}
      >
        {i18n.dir() ? (
          <ArrowForwardIos fontSize="medium" color="primary" />
        ) : (
          <ArrowBackIosNew fontSize="medium" color="primary" />
        )}
      </IconButton>
      {isRefs && (
        <Swiper
          spaceBetween={25}
          freeMode
          centeredSlidesBounds
          modules={[Navigation, A11y]}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          slidesPerView="auto"
        >
          {promotions.map((promotion: Promotion) => (
            <SwiperSlide key={promotion.id} style={{ width: 'auto' }}>
              <Card
                image={promotion.thumbnail_url}
                desktopImage={promotion.pictures[0]?.src || ''}
                title={promotion.name}
                description={promotion.description}
                date={promotion.date}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
};

export default memo(PromotionSlider);
