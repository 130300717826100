import React, { FC, useEffect, useState } from 'react';
import { Box, RadioGroup, Typography } from '@mui/material';
import { GroupModifier, Modifier } from 'types/Menu';
import { OptionCheckbox, OptionRadioButton } from 'components/generic';

interface GroupModifiersProps {
  groupModifiers: GroupModifier[];
}

const GroupModifiers: FC<GroupModifiersProps> = ({ groupModifiers }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleSelect = (value: number, maxCount: number, minCount: number) => {
    setSelectedItems((prevSelectedItems) => {
      const isPresent = prevSelectedItems.includes(value);
      const count = prevSelectedItems.length;

      // Если значение уже присутствует и количество элементов равно минимальному,
      // вернуть текущий список, так как мы не хотим удалять последний выбранный элемент.
      if (isPresent && count === minCount) {
        return prevSelectedItems;
      }

      // Если максимальное количество выбранных элементов уже достигнуто,
      // и новый элемент не был выбран, вернуть текущий список без первого элемента
      // и добавить новый выбранный элемент.
      if (count >= maxCount && !isPresent) {
        const newValue = prevSelectedItems.slice(1);
        newValue.push(value);
        return newValue;
      }

      // Если максимальное количество выбранных элементов уже достигнуто,
      // и новый элемент был уже выбран, вернуть новый список без нового выбранного элемента.
      if (count >= maxCount && isPresent) {
        const newValue = prevSelectedItems.filter((item) => item !== value);
        return newValue;
      }

      // Если минимальное количество элементов не достигнуто, добавить новый элемент.
      if (count < minCount) {
        return [...prevSelectedItems, value];
      }

      // Если ни одно из условий выше не выполнилось, значит минимальное количество элементов достигнуто,
      // и мы можем заменить первый элемент на новый выбранный элемент.
      const newValue = [...prevSelectedItems];
      newValue[0] = value;
      return newValue;
    });
  };

  useEffect(() => {
    groupModifiers.forEach((groupModifier) => {
      setSelectedItems([groupModifier.modifiers[0]?.id]);
    });
  }, []);

  return (
    <>
      {groupModifiers.map((group) => (
        <Box sx={{ marginBottom: '14px' }} key={group.id}>
          <Typography
            sx={{ fontWeight: '400', marginBottom: '10px' }}
            variant="h6"
          >
            {group.name}
          </Typography>
          {group.max_count < 1 ? (
            <RadioGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              aria-label={group.name}
              defaultValue={group.modifiers[0].id}
              name="radio-buttons-group"
            >
              {group.modifiers.map((modifier) => (
                <OptionRadioButton
                  key={modifier.id}
                  label={modifier.name}
                  group={group.id}
                  value={modifier.id}
                  price={modifier.price_kop}
                  defaultValue={group.modifiers[0].id}
                />
              ))}
            </RadioGroup>
          ) : (
            <>
              {group.modifiers.map((modifier: Modifier) => (
                <OptionCheckbox
                  selectedItems={selectedItems}
                  handleSelect={handleSelect}
                  key={modifier.id}
                  label={modifier.name}
                  groupId={group.id}
                  value={modifier.id}
                  price={modifier.price_kop}
                  maxCount={group.max_count}
                  minCount={group.min_count}
                />
              ))}
            </>
          )}
        </Box>
      ))}
    </>
  );
};

export default GroupModifiers;
