import React, { FC } from 'react';
import { Map, Welcome, Info, Advantages, Contacts } from 'components/about';
import { Banner } from 'components/generic';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { selectPageData } from 'store/selectors/pageSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectAvailableRestaurants,
  selectFirstAvailableRestaurant,
} from 'store/selectors/restaurantSelectors';
import { useScrollToBlock } from 'hooks';
import { Restaurant } from 'types/Restaurant';
import { Box } from '@mui/material';

const About: FC = () => {
  useScrollToBlock();
  const pageData = useAppSelector(selectPageData('about'));
  const restaurants = useAppSelector<Restaurant[]>(selectAvailableRestaurants);
  const { longitude, latitude } = useAppSelector<{
    longitude: number;
    latitude: number;
  }>(selectFirstAvailableRestaurant);
  const { t } = useTranslation();

  if (!pageData) {
    return null;
  }

  const {
    page_title,
    info_title_1,
    info_title_2,
    info_text_1,
    info_text_2,
    info_image,
    banner_image,
    advantages,
    is_contacts,
  } = pageData;

  const isAdvantages = advantages && advantages.length > 0;

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_ABOUT_PAGE_TITLE ||
            `${process.env.REACT_APP_NAME} | ${t('about_us')}`}
        </title>
        <meta
          name="description"
          content={process.env.REACT_APP_ABOUT_PAGE_DESCRIPTION || ''}
        />
      </Helmet>

      <Box sx={{ marginBottom: { xs: '20px', sm: '50px', md: '100px' } }}>
        <Banner title={page_title} image={banner_image} />
      </Box>

      <Welcome title={info_title_1} text={info_text_1} />
      <Info title={info_title_2} text={info_text_2} src={info_image} />
      {isAdvantages && <Advantages advantages={advantages} />}
      {is_contacts ? <Contacts /> : <></>}
      <Map
        zoom={11}
        longitude={longitude}
        latitude={latitude}
        restaurants={restaurants}
      />
    </>
  );
};

export default About;
