import { createSelector } from '@reduxjs/toolkit';
import i18next, { t } from 'i18next';
import { FormValues } from 'types/HookForms';
import { Ingredient, MenuCategory } from 'types/Menu';
import { formatPrice } from 'helpers';
import { RootState } from '../store';
import { selectPromotionsWithoutPromocode } from './promotionSelectors';

const selectMenu = (state: RootState) => state.menu;
/**
 * returns menu data
 */
export const selectMenuData = createSelector([selectMenu], (menu) => menu.menu);
/**
 * returns menu data is loading
 */
export const selectMenuIsLoading = createSelector(
  [selectMenu],
  (menuData) => menuData.isLoading
);
/**
 * returns selected category by ID
 */
export const selectCategory = (categoryId: number) =>
  createSelector(
    [selectMenuData],
    (menuData) =>
      menuData.filter((category: MenuCategory) => categoryId === category.id)[0]
  );
/**
 * returns items by category name
 */
export const selectItemsByCategoryName = (categoryId: number) =>
  createSelector([selectMenuData], (menuData) => {
    const category = menuData.find(
      (menuCategory: MenuCategory) => menuCategory.id === categoryId
    );

    return category?.items;
  });
/**
 * returns selected menu product
 */
export const selectMenuProduct = (itemId: number, categoryId: number) =>
  createSelector(
    [selectCategory(categoryId)],
    (menuCategory: MenuCategory) =>
      menuCategory?.items
        .map((item) => {
          const labels = item.label.map((label) => {
            return { ...label, order_by: label.order_by + 1 };
          });

          if (item.is_only_pickup) {
            labels.push({
              order_by: 0,
              text: i18next.t('only_pickup'),
              background_color: 'primary.main',
              text_color: 'primary.contrastText',
            });
          }

          return {
            ...item,
            label: [...labels].sort(
              (prev, next) => prev.order_by - next.order_by
            ),
          };
        })
        .filter((item) => item.id === itemId)[0]
  );
/**
 * returns price selected option
 */
export const selectOptionPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const selectedOption = product?.options.find((option) => {
      if (selectedValues.option) {
        return option.id === Number(selectedValues.option);
      }
      return option.id;
    });
    if (selectedOption) {
      return selectedOption.price_kop;
    }
    return 0;
  });
/**
 * returns total price selected modifiers
 */
export const selectModifiersPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    if (product) {
      const selectedModifiers = product.group_modifiers.map((group) => {
        if (group.max_count <= 1 && group.min_count >= 1) {
          const selectedModifier = group.modifiers.find(
            (modifier) => modifier.id === Number(selectedValues[group.id])
          );

          return selectedModifier ? selectedModifier?.price_kop : 0;
        }

        const selectedModifier = group.modifiers
          .filter((modifier) => {
            if (selectedValues[group.id]) {
              if (typeof selectedValues[group.id] === 'number') {
                return modifier.id === Number(selectedValues[group.id]);
              }
              return selectedValues[group.id].includes(modifier.id);
            }
            return false;
          })
          .map((modifier) => modifier.price_kop);

        return selectedModifier.length > 0
          ? selectedModifier.reduce((acc, currentValue) => acc + currentValue)
          : 0;
      });

      return selectedModifiers.reduce((sum, price) => sum + price, 0);
    }
    return 0;
  });
/**
 * returns total price selected ingredients
 */
export const selectIngredientsPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const values = selectedValues.ingredients || [];
    const selectedIngredients = product?.ingredients.filter(
      (ingredient: Ingredient) => values.includes(ingredient.id)
    );

    return (
      selectedIngredients?.reduce(
        (sum, ingredient: Ingredient) => sum + ingredient.price_kop,
        0
      ) || 0
    );
  });
/**
 * returns total price product
 */
export const selectCalculateProductPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector(
    [
      selectOptionPrice(selectedValues, itemId, categoryId),
      selectModifiersPrice(selectedValues, itemId, categoryId),
      selectIngredientsPrice(selectedValues, itemId, categoryId),
    ],
    (optionPrice: number, modifiers: number, ingredientPrice: number) => {
      const totalPriceKop = optionPrice + modifiers + ingredientPrice;
      const totalPrice = formatPrice(totalPriceKop || 0);

      return { totalPriceKop, totalPrice };
    }
  );
/**
 * returns card price
 */
export const selectMenuCardPrice = (itemId: number, categoryId: number) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const defaultPrice = product.options[0].price_kop;
    const defaultModifiersPrice = product.group_modifiers
      .map((group) => {
        if (group.max_count <= 1 && group.min_count >= 1) {
          return group.modifiers[0]?.price_kop;
        }
        return 0;
      })
      .reduce((prev, current) => prev + current, 0);

    return defaultPrice + defaultModifiersPrice;
  });
/**
 * returns converted data for display menu with promo
 */
export const selectMenuWithOffers = (promoCategoryName: string) =>
  createSelector(
    [selectMenuData, selectPromotionsWithoutPromocode],
    (menuData, promotions) => {
      const menuWithOffers = menuData
        .filter((category) => {
          const activeItems = category.items.filter((item) => item.is_active);
          return category.is_active && activeItems.length > 0;
        })
        .map((category) => {
          const activeItems = category.items.filter((item) => item.is_active);

          const categoryName = category.is_only_pickup
            ? `${category.name} (${t('only_pickup')})`
            : category.name;

          return {
            id: category.id.toString(),
            items: activeItems,
            name: categoryName,
            orderBy: category.order_by,
            isOnlyPickup: category.is_only_pickup,
          };
        })
        .sort((prev, next) => prev.orderBy - next.orderBy);

      if (promotions.length > 0) {
        return [
          { id: 'promo', items: [], name: promoCategoryName, orderBy: 0 },
          ...menuWithOffers,
        ];
      }

      return menuWithOffers;
    }
  );
/**
 * returns converted data for display menu tabs with promo
 */
export const selectMenuTabs = (promoCategoryName: string) =>
  createSelector(
    [selectMenuData, selectPromotionsWithoutPromocode],
    (menuData, promotions) => {
      const convertedMenu = menuData
        .filter((category) => {
          const activeItems = category.items.filter((item) => item.is_active);
          return category.is_active && activeItems.length > 0;
        })
        .map((category) => {
          return {
            id: category.id.toString(),
            name: category.name,
            orderBy: category.order_by,
          };
        })
        .sort((prev, next) => prev.orderBy - next.orderBy);

      if (promotions.length > 0) {
        return [
          { id: 'promo', name: promoCategoryName, orderBy: 0 },
          ...convertedMenu,
        ];
      }
      return convertedMenu;
    }
  );
/**
 * returns value is only pickup category
 */
export const selectIsOnlyPickupCategory = (categoryId: number) =>
  createSelector([selectMenuData], (menuData) => {
    const categories = menuData.find((category) => category.id === categoryId);
    return categories?.is_only_pickup || false;
  });
/**
 * returns menu pdf
 */
export const selectMenuPdf = createSelector([selectMenu], (menuData) => {
  const data = menuData.menuPdf;

  return data || [];
});
