import { useCallback, useMemo, useState, useEffect } from 'react';
import { useAuth } from 'hooks/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import {
  selectBasket,
  selectBasketRecommendations,
  selectCalculatedBasketResponse,
  selectCutlery,
  selectIsDelivery,
} from 'store/selectors/orderSelectors';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import { checkDelivery } from 'store/reducers/OrderSlice/ActionCreators';
import {
  selectAddressForBasket,
  selectAddressById,
} from 'store/selectors/addressSelectors';
import { Address } from 'types/Address';
import { MenuProduct } from 'types/Menu';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useSnackbar } from 'notistack';
import { SnackActions } from 'components/generic';
import { useTranslation } from 'react-i18next';
import { AppSettingsData } from 'types/AppSettings';
import { useWorkTime } from 'hooks/basket';
import { selectLocationCity } from 'store/selectors/citySelectors';
import { City } from 'types/City';

const useBasket = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    watch,
    setError,
    formState: { isValid },
    handleSubmit,
  } = methods;
  const {
    IS_CUTLERY_REQUIRED,
    MESSAGE_INFO_TYPE,
    MESSAGE_INFO_TEXT,
    ACTION_PROMOCODE,
  } = useAppSelector<AppSettingsData>(selectAppSettingsData);
  const deliveryAddressId = watch('place');
  const restaurantId = watch('restaurant');
  const receiving = watch('receiving');
  const { isAuth } = useAuth();
  const { clearAuthResponse } = accountSlice.actions;
  const api_key = useAppSelector<string>(selectApiKey);
  const cutleryCount = useAppSelector<number>(selectCutlery);
  const [authModal, setAuthModal] = useState<boolean>(false);
  const [addressModal, setAddressModal] = useState<boolean>(false);
  const products = useAppSelector<MenuProduct[]>(selectBasketRecommendations);
  const deliveryAddress = useAppSelector<Address | undefined>(
    selectAddressById(deliveryAddressId)
  );
  const selectedCity = useAppSelector<City>(selectLocationCity);
  const { message, success } = useAppSelector(selectCalculatedBasketResponse);
  const selectedAddressData = useAppSelector(selectAddressForBasket);
  const { addAddress, removeRestaurant, removeAddress, addRestaurant } =
    orderSlice.actions;
  const isDelivery = useAppSelector<boolean>(selectIsDelivery);
  const [firstCheckCutlery, setFirstCheckCutlery] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isWorkTime = useWorkTime();
  const basketItems = useAppSelector(selectBasket);

  const handleAuthModalOpen = useCallback(() => {
    setAuthModal(true);
  }, [authModal]);

  const handleAuthModalClose = useCallback(() => {
    setAuthModal(false);
    dispatch(clearAuthResponse());
  }, [authModal]);

  const handleAddressModalOpen = useCallback(() => {
    setAddressModal(true);
  }, [addressModal]);

  const handleAddressModalClose = useCallback(() => {
    setAddressModal(false);
  }, [addressModal]);

  const handleAddressButton = useMemo(() => {
    if (isAuth) {
      return handleAddressModalOpen;
    }
    return handleAuthModalOpen;
  }, [isAuth]);

  useEffect(() => {
    if (deliveryAddress) {
      dispatch(
        checkDelivery({
          api_key,
          body: {
            city_id: deliveryAddress.city,
            street: deliveryAddress.street,
            house: deliveryAddress.house,
          },
        })
      );
    }
  }, [deliveryAddress]);

  const onSubmit = (formData: any) => {
    if (cutleryCount === 0 && IS_CUTLERY_REQUIRED && !firstCheckCutlery) {
      enqueueSnackbar(t('please_specify_the_number_of_cutlery'), {
        action: SnackActions,
        variant: 'warning',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
      setFirstCheckCutlery(true);
    } else {
      dispatch(
        updateAccountData({
          api_key,
          body: {
            first_name: formData.name,
            phone: formData.phone,
          },
        })
      )
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/payment');
          } else if (res.reason === 'PHONE_ALREADY_USE') {
            setError('phone', {
              type: 'server',
            });
            enqueueSnackbar(t('phone_already_use'), {
              action: SnackActions,
              variant: 'error',
              autoHideDuration: 3000,
              preventDuplicate: true,
            });
          }
        });
    }
  };

  const closeMessage = useMemo(() => {
    if (selectedCity) {
      const openTime = selectedCity.delivery_start.slice(0, 5);
      return `Мы закрыты. Откроемся в ${openTime}`;
    }
    return 'Мы закрыты.';
  }, [selectedCity]);

  const handleCheckoutButton = () => {
    if (
      (isAuth && isValid && !isWorkTime && receiving === 2) ||
      (isAuth && isValid && isWorkTime)
    ) {
      handleSubmit(onSubmit)();
    }
    if (isAuth && receiving === 1 && !isWorkTime) {
      enqueueSnackbar(closeMessage, {
        action: SnackActions,
        variant: 'info',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    }
    if (isAuth && !isValid) {
      enqueueSnackbar(t('please_fill_in_the_required_fields'), {
        action: SnackActions,
        variant: 'error',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    }
    if (!isAuth) {
      handleAuthModalOpen();
    }
  };

  useEffect(() => {
    if (MESSAGE_INFO_TYPE === 1 || MESSAGE_INFO_TYPE === 2) {
      enqueueSnackbar(MESSAGE_INFO_TEXT, {
        action: SnackActions,
        variant: 'info',
        autoHideDuration: 10000,
      });
    }
  }, [MESSAGE_INFO_TYPE]);

  useEffect(() => {
    if (!success && message) {
      enqueueSnackbar(message, {
        action: SnackActions,
        variant: 'error',
        autoHideDuration: 10000,
      });
    }
  }, [message, success]);

  useEffect(() => {
    if (isDelivery) {
      dispatch(addAddress(selectedAddressData));
      dispatch(removeRestaurant());
    } else {
      dispatch(removeAddress());
      dispatch(addRestaurant(Number(restaurantId)));
    }
  }, [selectedAddressData, isDelivery]);

  useEffect(() => {
    const isPickupItem = basketItems.some(
      (item) => item.is_only_pickup || item.is_only_pickup_category
    );

    if (isPickupItem) {
      enqueueSnackbar(t('only_pickup_message'), {
        action: SnackActions,
        variant: 'info',
        autoHideDuration: 10000,
        preventDuplicate: true,
      });
    }
  }, []);

  return {
    onSubmit,
    methods,
    authModal,
    handleAuthModalClose,
    addressModal,
    handleAddressModalClose,
    handleAddressButton,
    handleCheckoutButton,
    products,
    isPromocode: ACTION_PROMOCODE,
  };
};

export default useBasket;
