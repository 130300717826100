import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketProduct, CalculatedBasket } from 'types/Basket';
import { CheckDeliveryResponse } from 'types/Delivery';
import {
  CreateOrder,
  CreateOrderAddress,
  CreateOrderClient,
  ResponseCreateOrder,
  OrderPromotion,
  OrderHistoryItem,
  CreateOrderPayment,
} from 'types/Order';
import {
  calculateBasket,
  checkDelivery,
  createOrder,
  getOrderHistory,
} from './ActionCreators';

interface OrderState {
  basket: BasketProduct[];
  calculatedBasket: CalculatedBasket;
  order: CreateOrder;
  orderHistory: OrderHistoryItem[];
  orderResponse: ResponseCreateOrder;
  checkDelivery: CheckDeliveryResponse;
  calculatedBasketIsLoading: boolean;
  orderIsLoading: boolean;
  orderHistoryIsLoading: boolean;
  orderResponseIsLoading: boolean;
  checkDeliveryIsLoading: boolean;
  error: string;
}

const initialState: OrderState = {
  basket: [],
  calculatedBasket: {
    data: {
      actions: [],
      available_actions: [],
      basket_items: [],
      delivery_price: 0,
      presents: [],
      result_total_price_kop: 0,
      total_price_kop: 0,
    },
    reason: null,
    success: false,
    message_type: 0,
    message: null,
  },
  order: {
    brand_id: null,
    delivery_type: 0,
    platform_type: 2,
    change_from: 0,
    order_type: 0,
    call_back: 1,
    cutlery: 0,
    comment: '',
    pickup_time: null,
    client: null,
    address: null,
    payment: {
      payment_type: null,
      points: 0,
    },
    restaurant_id: null,
    basket: [],
    actions: [],
  },
  orderHistory: [],
  orderResponse: {
    data: null,
    reason: '',
    success: false,
    message_type: 0,
    message: '',
  },
  checkDelivery: {
    data: null,
    message: '',
    message_type: 0,
    reason: '',
    success: false,
  },
  calculatedBasketIsLoading: false,
  orderIsLoading: false,
  orderHistoryIsLoading: false,
  orderResponseIsLoading: false,
  checkDeliveryIsLoading: false,
  error: '',
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addCallBack: (state) => {
      state.order.call_back = 1;
    },
    addPaymentType: (state, action: PayloadAction<CreateOrderPayment>) => {
      state.order.payment = action.payload;
    },
    addPaymentData: (state, action: PayloadAction<CreateOrderPayment>) => {
      state.order.payment = { ...state.order.payment, ...action.payload };
    },
    removePoints: (state, action: PayloadAction<number>) => {
      state.order.payment.points = action.payload;
    },
    selectDeliveryType: (state, action: PayloadAction<number>) => {
      state.order.delivery_type = action.payload;
    },
    setBrandId: (state, action: PayloadAction<number>) => {
      state.order.brand_id = action.payload;
    },
    addClientData: (state, action: PayloadAction<CreateOrderClient>) => {
      state.order.client = action.payload;
    },
    addAddress: (state, action: PayloadAction<CreateOrderAddress | null>) => {
      state.order.address = action.payload;
    },
    removeAddress: (state) => {
      state.order.address = null;
    },
    addBasket: (state, action: PayloadAction<any>) => {
      state.order.basket = action.payload;
    },
    addRestaurant: (state, action: PayloadAction<number>) => {
      state.order.restaurant_id = action.payload;
    },
    removeRestaurant: (state) => {
      state.order.restaurant_id = null;
    },
    addPromotion: (state, action: PayloadAction<OrderPromotion>) => {
      const isPromotion = state.order.actions.find(
        (promotion) => promotion.action_id === action.payload.action_id
      );

      if (!isPromotion) {
        state.order.actions.push(action.payload);
      }
    },
    removePromotion: (state, action: PayloadAction<number>) => {
      state.order.actions = state.order.actions.filter(
        (promotion) => promotion.action_id !== action.payload
      );
    },
    addCutlery: (state) => {
      state.order.cutlery += 1;
    },
    removeCutlery: (state) => {
      if (state.order.cutlery > 0) {
        state.order.cutlery -= 1;
      }
    },
    addPickupTime: (state, action: PayloadAction<string>) => {
      state.order.pickup_time = action.payload;
    },
    clearPickupTime: (state) => {
      state.order.pickup_time = null;
    },
    addComment: (state, action: PayloadAction<string>) => {
      state.order.comment = action.payload;
    },
    clearOrder: (state) => {
      state.basket = [];
      state.order = {
        delivery_type: 0,
        platform_type: 2,
        change_from: 0,
        order_type: 0,
        call_back: 0,
        cutlery: 0,
        comment: '',
        pickup_time: null,
        client: null,
        address: null,
        payment: {
          payment_type: null,
          points: 0,
        },
        restaurant_id: null,
        basket: [],
        actions: [],
      };
    },

    addProduct(state, action: PayloadAction<BasketProduct>) {
      const product = state.basket.find((item) => {
        const productId = item.id === action.payload.id;
        const isSameIngredients =
          JSON.stringify(item.ingredients) ===
          JSON.stringify(action.payload.ingredients);
        const isSameCookingOption =
          item.cooking_option_id === action.payload.cooking_option_id;
        const isSameModifiers =
          JSON.stringify(item.group_modifiers) ===
          JSON.stringify(action.payload.group_modifiers);
        if (
          productId &&
          isSameIngredients &&
          isSameCookingOption &&
          isSameModifiers
        ) {
          return item;
        }
        return false;
      });

      if (product) {
        product.count += 1;
      } else {
        state.basket.push(action.payload);
      }
    },
    removeProduct(state, action: PayloadAction<number>) {
      state.basket = state.basket.filter(
        (item) => item.basketId !== action.payload
      );
    },
    addProductCount(state, action: PayloadAction<number>) {
      state.basket.map((product) => {
        if (product.basketId === action.payload) {
          product.count += 1;
        }
        return product;
      });
    },
    removeProductCount(state, action: PayloadAction<number>) {
      state.basket.map((product) => {
        if (product.basketId === action.payload) {
          product.count -= 1;
        }
        return product;
      });
    },
    clearBasket(state) {
      state.basket = [];
    },
  },
  extraReducers: {
    [createOrder.fulfilled.type]: (
      state,
      action: PayloadAction<ResponseCreateOrder>
    ) => {
      state.orderIsLoading = false;
      state.error = '';
      state.orderResponse = action.payload;
    },
    [createOrder.pending.type]: (state) => {
      state.orderIsLoading = true;
    },
    [createOrder.rejected.type]: (state, action: PayloadAction<string>) => {
      state.orderIsLoading = false;
      state.error = action.payload;
    },
    [getOrderHistory.fulfilled.type]: (
      state,
      action: PayloadAction<OrderHistoryItem[]>
    ) => {
      state.orderHistoryIsLoading = false;
      state.error = '';
      state.orderHistory = action.payload;
    },
    [getOrderHistory.pending.type]: (state) => {
      state.orderHistoryIsLoading = true;
    },
    [getOrderHistory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.orderHistoryIsLoading = false;
      state.error = action.payload;
    },
    [checkDelivery.fulfilled.type]: (
      state,
      action: PayloadAction<CheckDeliveryResponse>
    ) => {
      state.checkDeliveryIsLoading = false;
      state.error = '';
      state.checkDelivery = action.payload;
    },
    [checkDelivery.pending.type]: (state) => {
      state.checkDeliveryIsLoading = true;
    },
    [checkDelivery.rejected.type]: (state, action: PayloadAction<string>) => {
      state.checkDeliveryIsLoading = false;
      state.error = action.payload;
    },
    [calculateBasket.fulfilled.type]: (
      state,
      action: PayloadAction<CalculatedBasket>
    ) => {
      state.calculatedBasketIsLoading = false;
      state.error = '';
      state.calculatedBasket = action.payload;
    },
    [calculateBasket.pending.type]: (state) => {
      state.calculatedBasketIsLoading = true;
    },
    [calculateBasket.rejected.type]: (state, action: PayloadAction<string>) => {
      state.calculatedBasketIsLoading = false;
      state.error = action.payload;
    },
  },
});

export default orderSlice.reducer;
