import React, { FC } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';
import { Card } from 'components/promotions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { MainTitle } from 'components/generic';
import {
  selectPromotionsData,
  selectPromotionsDataIsLoading,
} from 'store/selectors/promotionSelectors';

const Promotions: FC = () => {
  const promotionIsLoading = useAppSelector(selectPromotionsDataIsLoading);
  const promotionsData = useAppSelector(selectPromotionsData);

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        {promotionIsLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '70vh',
              width: '100%',
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box sx={{ marginBottom: '40px' }}>
              <MainTitle>Offers</MainTitle>
            </Box>
            <Box
              sx={{
                display: 'grid',
                columnGap: { xs: 3, sm: 3, md: 5 },
                rowGap: { xs: 4, md: 5 },
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  lg: 'repeat(3, 1fr)',
                },
              }}
            >
              {promotionsData.map((card) => (
                <Card
                  key={card.id}
                  title={card.name}
                  description={card.description}
                  desktopImage={card.pictures[0].src}
                  mobileImage={card.thumbnail_url}
                  date={card.date}
                />
              ))}
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Promotions;
