import { useMemo } from 'react';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBlogPosts } from 'store/selectors/blogSelectors';
import { selectPageData } from 'store/selectors/pageSelectors';
import { selectMenuPdf } from 'store/selectors/menuSelectors';

const useMenuTabs = () => {
  const { BOOKING_TABLE } = useAppSelector(selectAppSettingsData);
  const blogPosts = useAppSelector(selectBlogPosts);
  const isAboutPage = useAppSelector(selectPageData('about'));
  const isDeliveryPage = useAppSelector(selectPageData('delivery'));
  const isVacanciesPage = useAppSelector(selectPageData('vacancies'));
  const isReviewsPage = useAppSelector(selectPageData('reviews'));
  const isReservePage = useAppSelector(selectPageData('reserve'));
  const isHomePage = useAppSelector(selectPageData('home'));
  const menuPdf = useAppSelector(selectMenuPdf);

  const menuTabs = useMemo(() => {
    const tabs = [];

    if (isHomePage) {
      tabs.push({
        label: 'home',
        path: '/',
        order_by: 1,
      });
      tabs.push({
        label: 'menu',
        path: '/menu',
        order_by: 2,
      });
    } else {
      tabs.push({
        label: 'menu',
        path: '/',
        order_by: 1,
      });
    }

    if (isAboutPage) {
      tabs.push({
        label: 'about',
        path: '/about',
        order_by: 6,
      });
    }

    if (isDeliveryPage) {
      tabs.push({
        label: 'delivery',
        path: '/delivery',
        order_by: 4,
      });
    }

    if (isReviewsPage) {
      tabs.push({
        label: 'reviews',
        path: '/reviews',
        order_by: 7,
      });
    }

    if (isReservePage) {
      tabs.push({
        label: 'reserve',
        path: '/reserve',
        order_by: 5,
      });
    }

    if (isVacanciesPage) {
      tabs.push({
        label: 'vacancies',
        path: '/vacancies',
        order_by: 9,
      });
    }

    if (blogPosts.length > 0) {
      tabs.push({
        label: 'blog',
        path: '/blog',
        order_by: 8,
      });
    }

    if (menuPdf.length > 0) {
      tabs.push({
        label: 'menu_pdf',
        path: '/menu_pdf',
        order_by: 3,
      });
    }

    return tabs.sort((prev, next) => prev.order_by - next.order_by);
  }, [
    BOOKING_TABLE,
    blogPosts,
    isHomePage,
    isAboutPage,
    isDeliveryPage,
    isReviewsPage,
    isReservePage,
    isVacanciesPage,
    menuPdf,
  ]);

  return menuTabs;
};

export default useMenuTabs;
