import { useEffect, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { getAccount } from 'store/reducers/AccountSlice/ActionCreators';
import { getAddresses } from 'store/reducers/AddressSlice/ActionCreators';
import {
  getAppSettings,
  getServerTime,
} from 'store/reducers/AppSettingsSlice/ActionCreators';
import { getCities } from 'store/reducers/CitySlice/ActionCreators';
import { getMenu, getMenuPdf } from 'store/reducers/MenuSlice/ActionCreators';
import { getPages } from 'store/reducers/PagesSlice/ActionCreators';
import { getPromotions } from 'store/reducers/PromotionSlice/ActionCreators';
import { getRestaurantList } from 'store/reducers/RestaurantSlice/ActionCreators';
import { getSocialMedia } from 'store/reducers/SocialMediaSlice/ActionCreators';
import { useTranslation } from 'react-i18next';
import { getBlogPosts } from 'store/reducers/BlogSlice/ActionCreators';
import { useAuth } from 'hooks/auth';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { getBrands } from 'store/reducers/BrandSlice/ActionCreators';
import { selectBrandId, selectBrands } from 'store/selectors/brandsSelectors';
import { selectCityId } from 'store/selectors/citySelectors';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { calculateBasket } from 'store/reducers/OrderSlice/ActionCreators';
import {
  selectBasketDataForRequest,
  selectIsDelivery,
  selectOrderPromotions,
} from 'store/selectors/orderSelectors';
import { selectAddressForBasket } from 'store/selectors/addressSelectors';
import { useLocation } from 'react-router-dom';
import { brandSlice } from 'store/reducers/BrandSlice/BrandSlice';

const useInitApp = () => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  const { i18n } = useTranslation();
  const { setBrandId } = orderSlice.actions;
  const { selectBrand } = brandSlice.actions;
  const lang = useMemo(() => {
    return i18n.language.slice(0, 2);
  }, [i18n.language]);
  const api_key = useAppSelector(selectApiKey);
  const cityId = useAppSelector(selectCityId);
  const brandId = useAppSelector(selectBrandId);
  const basketData = useAppSelector(selectBasketDataForRequest);
  const selectedAddressData = useAppSelector(selectAddressForBasket);
  const isDelivery = useAppSelector(selectIsDelivery);
  const promotions = useAppSelector(selectOrderPromotions);
  const brands = useAppSelector(selectBrands);
  const location = useLocation();

  const brandValue = useMemo(() => {
    const brandParam = Number(location.pathname.split('/').pop());

    if (brands.find((brand: { id: number }) => brand.id === brandParam)) {
      sessionStorage.setItem('brandModalIsOpened', 'true');
      return brandParam;
    }
    return brandId;
  }, [location.pathname, brandId, brands]);

  useEffect(() => {
    const brandsCount = brands.length;

    if (brandsCount === 1) {
      dispatch(selectBrand(brands[0].id));
    }
  }, [brands]);

  useEffect(() => {
    if (brandValue) {
      dispatch(selectBrand(brandValue));
      dispatch(setBrandId(brandValue));
    }
  }, [brandValue, dispatch]);

  useEffect(() => {
    dispatch(getBrands(lang))
      .unwrap()
      .then((res) => {
        if (res.length <= 0) {
          dispatch(getCities({ lang }));
        }
      });
  }, [dispatch, lang]);

  const loadMenu = useCallback(() => {
    dispatch(
      getMenu({
        lang,
        city: cityId || undefined,
        brand: brandId || undefined,
      })
    );
  }, [dispatch, lang, cityId, brandId]);

  useEffect(() => {
    if (brandId) {
      dispatch(getCities({ lang, brand: brandId || undefined }));
    }
  }, [brandId, dispatch, lang]);

  useEffect(() => {
    dispatch(getAppSettings({ lang, brand: brandId || undefined }));
    dispatch(getRestaurantList({ lang, brand: brandId || undefined }));
    dispatch(getServerTime());
    dispatch(getPages(lang));
    dispatch(getSocialMedia({ brand: brandId || undefined }));
    dispatch(getBlogPosts(lang));
    dispatch(getMenuPdf({ lang, brand: brandId || undefined }));
  }, [lang, cityId, brandId]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getAccount(api_key));
      dispatch(getAddresses(api_key));
    }
  }, [api_key, isAuth]);

  useEffect(() => {
    loadMenu();

    const intervalId = setInterval(() => {
      loadMenu();
    }, 3600000);

    return () => {
      clearInterval(intervalId);
    };
  }, [loadMenu]);

  useEffect(() => {
    dispatch(
      getPromotions({
        api_key,
        lang,
        platform: 2,
        city_id: cityId || undefined,
        brand_id: brandId || undefined,
      })
    );
  }, [cityId, brandId]);

  useEffect(() => {
    dispatch(
      calculateBasket({
        api_key,
        platform: 2,
        brand_id: 0,
        body: {
          basket: basketData,
          is_delivery: isDelivery,
          address: selectedAddressData,
          actions: promotions,
        },
      })
    );
  }, [isDelivery, selectedAddressData, basketData, promotions]);
};

export default useInitApp;
