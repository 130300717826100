import { useState, useMemo, useEffect } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectServerTime } from 'store/selectors/appSelectors';
import {
  selectLocationCity,
  selectTimeZone,
} from 'store/selectors/citySelectors';
import { selectIsDelivery } from 'store/selectors/orderSelectors';
import { SelectData } from 'types/SelectData';
import moment from 'moment-timezone';

const useTimeList = (dayValue: string, updateTimeList: boolean) => {
  const selectedCity = useAppSelector(selectLocationCity);
  const isDelivery = useAppSelector(selectIsDelivery);
  const serverTime = useAppSelector(selectServerTime);
  const [availableTimeList, setAvailableTimeList] = useState<SelectData[]>([]);
  const timeZone = useAppSelector<string>(selectTimeZone);
  const currentDayOfWeek = moment.tz(serverTime, timeZone).day();
  const [days] = useState([
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]);

  const day = useMemo(() => {
    return dayValue ? moment(dayValue).day() : currentDayOfWeek;
  }, [dayValue, selectedCity]);

  const workDay = useMemo(() => {
    const workDaySchedule = selectedCity?.work_hours.find(
      (item) => item.week_day === days[day]
    );
    return workDaySchedule;
  }, [selectedCity, days, day, dayValue]);

  const deliveryStart = useMemo(() => {
    if (workDay) {
      return workDay.delivery_start || '';
    }
    return selectedCity?.delivery_start || '';
  }, [workDay, dayValue]);

  const deliveryEnd = useMemo(() => {
    if (workDay) {
      return workDay.delivery_end || '';
    }
    return selectedCity?.delivery_end || '';
  }, [workDay, dayValue]);

  const orderStart = useMemo(() => {
    if (workDay) {
      return workDay.order_start || '';
    }
    return selectedCity?.order_start || '';
  }, [workDay, dayValue]);

  const orderEnd = useMemo(() => {
    if (workDay) {
      return workDay.order_end || '';
    }
    return selectedCity?.order_end || '';
  }, [workDay, dayValue]);

  const startTime = useMemo(() => {
    return isDelivery ? deliveryStart : orderStart;
  }, [isDelivery, deliveryStart, orderStart, deliveryEnd, orderEnd]);

  const endTime = useMemo(() => {
    return isDelivery ? deliveryEnd : orderEnd;
  }, [isDelivery, deliveryStart, orderStart, deliveryEnd, orderEnd]);

  const isTheSameDay = useMemo(() => {
    const currentDate = moment.tz(serverTime, timeZone).format('DD/MM/YYYY');
    const selectedDate = moment.tz(dayValue, timeZone).format('DD/MM/YYYY');

    return currentDate === selectedDate;
  }, [dayValue, timeZone]);

  const timeSplit = (time: string) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return {
      hours,
      minutes,
      seconds,
    };
  };

  const minDate = useMemo(() => {
    const date = new Date(serverTime);
    const offsetTimeStartValue = selectedCity?.default_delivery_time_start;
    const millisecondsToAdd = offsetTimeStartValue * 60 * 1000;
    const newTime = date.getTime() + millisecondsToAdd;
    date.setTime(newTime);

    return date;
  }, [serverTime]);

  useEffect(() => {
    const list = [];
    const offsetTimeStartValue = selectedCity?.default_delivery_time_start;
    const offsetTimeEndValue = selectedCity?.default_delivery_time_end;
    const start = moment(deliveryStart, 'HH:mm');
    const end = moment(deliveryEnd, 'HH:mm');
    const timeInterval = 15;
    const selectedDateValue = moment(dayValue);

    const startDate = moment(serverTime);
    const endDate = moment(serverTime);
    const startTime = timeSplit(deliveryStart);
    const endTime = timeSplit(deliveryEnd);

    startDate.set({
      hour: startTime.hours,
      minute: startTime.minutes,
      second: startTime.seconds,
    });

    startDate.add(offsetTimeStartValue, 'minutes');

    endDate.set({
      hour: endTime.hours,
      minute: endTime.minutes,
      second: endTime.seconds,
    });

    endDate.subtract(offsetTimeEndValue, 'minutes');

    if (startDate.isAfter(endDate)) {
      for (
        let current = start;
        current <= end;
        current.add(timeInterval, 'minutes')
      ) {
        list.push({
          label: current.format('HH:mm'),
          value: current.format('HH:mm'),
        });
      }

      const availableTime = list.filter((item) => {
        const currentTime = startDate.format('HH:mm');
        return currentTime < item.value.toString();
      });

      if (startDate > selectedDateValue) {
        setAvailableTimeList([]);
      } else {
        setAvailableTimeList(availableTime);
      }
    } else {
      start.add(offsetTimeStartValue, 'minutes');
      end.subtract(offsetTimeEndValue, 'minutes');

      for (
        let current = start;
        current <= end;
        current.add(timeInterval, 'minutes')
      ) {
        list.push({
          label: current.format('HH:mm'),
          value: current.format('HH:mm'),
        });
      }

      const availableTime = list.filter((item) => {
        const currentTime = moment
          .tz(serverTime, timeZone)
          .add(offsetTimeStartValue, 'minutes')
          .format('HH:mm');
        return currentTime < item.value.toString();
      });

      if (currentDayOfWeek === day && isTheSameDay) {
        setAvailableTimeList(availableTime);
      } else {
        setAvailableTimeList(list);
      }
    }
  }, [
    currentDayOfWeek,
    startTime,
    endTime,
    serverTime,
    day,
    isTheSameDay,
    updateTimeList,
  ]);

  return { availableTimeList, minDate };
};

export default useTimeList;
