import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SocialMediaItem } from 'types/SocialMedia';

export const getSocialMedia = createAsyncThunk(
  'social_media/get_social_media',
  async (params: { brand?: number }, thunkAPI) => {
    const { brand } = params;
    try {
      const response = await axios.get<SocialMediaItem[]>(
        `${process.env.REACT_APP_V1}/social/`,
        {
          params: {
            brand,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't get the social media. Error:${e}`);
    }
  }
);
