import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Typography, Paper, Stack, Tooltip } from '@mui/material';
import {
  Button,
  CardProduct,
} from 'components/basket/BasketContent/Promo/Card';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import {
  selectOrderPromotions,
  selectActivePromo,
} from 'store/selectors/orderSelectors';
import { PromoProduct } from 'types/Promotion';
import { PromoProductsModal } from 'components/modals';
import { useTranslation } from 'react-i18next';
import { OrderPromotion } from 'types/Order';
import { useTrimmed } from 'hooks';

interface CardProps {
  title: string;
  id: number;
  isActive: boolean;
  promoProducts: PromoProduct[];
}

const Card: FC<CardProps> = ({ title, id, isActive, promoProducts }) => {
  const dispatch = useAppDispatch();
  const { addPromotion, removePromotion } = orderSlice.actions;
  const promotions = useAppSelector(selectOrderPromotions);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isActivePromo = useAppSelector(selectActivePromo(id));
  const { t } = useTranslation();
  const trimmedTitle = useTrimmed(title, 30);

  const isModal = useMemo(() => {
    return promoProducts.length >= 1;
  }, [promoProducts.length]);

  const isPromotion = useMemo(() => {
    return promotions.find(
      (promotion: OrderPromotion) => promotion.action_id === id
    );
  }, [promotions]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  const handleTogglePromo = useCallback(() => {
    if (isPromotion) {
      dispatch(removePromotion(id));
    } else {
      dispatch(addPromotion({ action_id: id }));
    }
  }, [isPromotion]);

  useEffect(() => {
    if (isActive && promoProducts.length > 0) {
      dispatch(
        addPromotion({
          action_id: id,
          item_option_id: promoProducts[0].item_option,
        })
      );
    } else {
      dispatch(
        addPromotion({
          action_id: id,
        })
      );
    }
  }, [isActive]);

  return (
    <>
      <Box
        sx={{
          margin: '20px 2px',
          height: { xs: '130px', md: '200px' },
          padding: { xs: '14px 12px 12px 12px', md: '30px 16px 16px 16px' },
          borderRadius: '20px',
          border: '1px solid',
          borderColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: 'none',
          position: 'relative',
        }}
        component={Paper}
      >
        <Box
          sx={{
            backgroundColor:
              isActivePromo || isActive ? 'primary.main' : 'secondary.main',
            position: 'absolute',
            top: { xs: '-10px', sm: '-19px' },
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            fontSize: { xs: '10px', sm: '16px', md: '20px' },
            lineHeight: { xs: '11px', sm: '16px', md: '26px' },
            fontWeight: '300',
            padding: { xs: '4px 8px', sm: '6px 15px' },
            color:
              isActivePromo || isActive
                ? 'primary.contrastText'
                : 'text.secondary',
          }}
        >
          {isActivePromo || isActive ? t('applied') : t('available')}
        </Box>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={title}
          placement="bottom"
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '20px' },
              lineHeight: { xs: '16px', md: '23px' },
              fontWeight: '300',
            }}
          >
            {trimmedTitle}
          </Typography>
        </Tooltip>

        <Stack direction="column" spacing={1}>
          {promoProducts.length > 0 &&
            promoProducts
              .slice(0, 2)
              .map((product) => (
                <CardProduct
                  key={product.id}
                  itemId={product.item}
                  categoryId={product.menu}
                />
              ))}
        </Stack>

        {!isActive ? (
          <Button
            isActive={isActivePromo}
            onClick={
              isModal && !isPromotion ? handleOpenModal : handleTogglePromo
            }
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '50px',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '16px', md: '20px' },
                color: 'text.primary',
                fontWeight: '300',
                lineHeight: { xs: '20px', md: '26px' },
                textAlign: 'center',
              }}
            >
              {t('auto_applied')}
            </Typography>
          </Box>
        )}
      </Box>

      <PromoProductsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        promoProducts={promoProducts}
        actionId={id}
      />
    </>
  );
};

export default Card;
