import React, { FC, memo } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { Category, Nav } from 'components/menu';
import { useMenu } from 'hooks/menu';
import { Helmet } from 'react-helmet';
import { MenuProduct } from 'types/Menu';

const Menu: FC = () => {
  const {
    t,
    opacity,
    activeCategoryId,
    menuWithOffers,
    categoryRefs,
    rootMargin,
    handleScrollTo,
    handleSetActiveCategory,
    isLoading,
  } = useMenu();

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_MENU_PAGE_TITLE ||
            `${process.env.REACT_APP_NAME} | ${t('menu')}`}
        </title>
        <meta
          name="description"
          content={process.env.REACT_APP_MENU_PAGE_DESCRIPTION || ''}
        />
      </Helmet>
      <Box sx={{ minHeight: '90vh' }}>
        {isLoading ? (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Nav
              activeCategoryId={activeCategoryId}
              handleScrollTo={handleScrollTo}
            />
            <Box sx={{ padding: { xs: '60px 0', sm: '80px 0' } }}>
              <Container>
                <Box sx={{ opacity: `${opacity}`, transition: 'opacity 0.3s' }}>
                  {menuWithOffers.map(
                    (category: {
                      id: string;
                      items: MenuProduct[];
                      name: string;
                    }) => (
                      <Category
                        key={category.id}
                        items={category.items}
                        categoryId={category.id}
                        categoryName={category.name}
                        activeCategoryId={activeCategoryId}
                        handleSetActiveCategory={handleSetActiveCategory}
                        categoryRefs={categoryRefs}
                        rootMargin={rootMargin}
                      />
                    )
                  )}
                </Box>
              </Container>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default memo(Menu);
