import React, { FC, useMemo, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Stack,
  Card as MUICard,
  CardActionArea,
} from '@mui/material';
import {
  selectIsAgeLimit,
  selectAgeLimitText,
} from 'store/selectors/brandsSelectors';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { AgeModal } from 'components/modals';
import { brandSlice } from 'store/reducers/BrandSlice/BrandSlice';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  id: number;
  name: string;
  description: string;
  logo: string;
  image: string;
  label: string;
  handleClose: () => void;
}

const Card: FC<CardProps> = ({
  id,
  name,
  description,
  logo,
  image,
  label,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const { selectBrand } = brandSlice.actions;
  const isAgeLimit = useAppSelector(selectIsAgeLimit(id));
  const ageLimiText = useAppSelector(selectAgeLimitText(id));
  const [ageModal, setAgeModal] = useState<boolean>(false);
  const { clearOrder } = orderSlice.actions;
  const navigate = useNavigate();

  const handleAgeModalOpen = useCallback(() => {
    setAgeModal(true);
  }, [ageModal]);

  const handleAgeModalClose = useCallback(() => {
    setAgeModal(false);
  }, [ageModal]);

  const handleSelectBrand = () => {
    dispatch(selectBrand(id));
    sessionStorage.setItem('brandModalIsOpened', 'true');
    navigate('/');
    handleClose();
    handleAgeModalClose();
    dispatch(clearOrder());
  };

  const handleClickOnCard = useMemo(() => {
    if (isAgeLimit) {
      return handleAgeModalOpen;
    }
    return handleSelectBrand;
  }, [isAgeLimit, id]);

  return (
    <>
      <MUICard
        sx={{
          cursor: 'pointer',
          borderRadius: '10px',
          boxShadow: 'none',
        }}
        onClick={handleClickOnCard}
      >
        <CardActionArea sx={{ padding: '15px' }}>
          <Box
            sx={{
              height: '150px',
              minWidth: '350px',
              overflow: 'hidden',
              position: 'relative',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
          >
            {logo && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '160px',
                  height: '100px',
                  transform: 'translate(-50%, -50%)',
                }}
                alt="logo"
                src={logo}
                component="img"
              />
            )}
            <Box
              component="img"
              sx={{
                objectFit: 'cover',
                objectPosition: 'center',
                width: '100%',
                height: '100%',
                minHeight: '100%',
              }}
              alt="image"
              src={image}
            />
          </Box>

          <Stack direction="column" spacing={0.5}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
            >
              <Typography
                sx={{ fontSize: '20px', lineHeight: '23px', fontWeight: '600' }}
              >
                {name}
              </Typography>
              {label && (
                <Typography
                  sx={{
                    display: 'inline-flex',
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontWeight: '600',
                    color: 'primary.contrastText',
                    padding: '4px 12px',
                    borderRadius: '10px',
                    backgroundColor: 'primary.main',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {label}
                </Typography>
              )}
            </Stack>

            <Typography
              sx={{ fontSize: '14px', lineHeight: '16px', fontWeight: '300' }}
            >
              {description}
            </Typography>
          </Stack>
        </CardActionArea>
      </MUICard>
      <AgeModal
        isOpen={ageModal}
        onClose={handleAgeModalClose}
        handleSelectBrand={handleSelectBrand}
        text={ageLimiText}
      />
    </>
  );
};

export default Card;
