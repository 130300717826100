import React, { FC } from 'react';
import { IconButton, Link } from '@mui/material';
import {
  FacebookIcon,
  InstagramIcon,
  NoneIcon,
  OdnoklassnikiIcon,
  TwitterIcon,
  VkontakteIcon,
} from 'components/icons';

interface SocialLink {
  link: string;
  name: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

const SocialLink: FC<SocialLink> = ({ link, name, color }) => {
  const renderSocialIcon = (socialName: string) => {
    switch (socialName) {
      case 'instagram':
        return <InstagramIcon fontSize="large" />;
      case 'vkontakte':
        return <VkontakteIcon fontSize="large" />;
      case 'odnoklassniki':
        return <OdnoklassnikiIcon fontSize="large" />;
      case 'facebook':
        return <FacebookIcon fontSize="large" />;
      case 'twitter':
        return <TwitterIcon fontSize="large" />;
      default:
        return <NoneIcon fontSize="large" />;
    }
  };

  return (
    <>
      {link ? (
        <IconButton
          color={color}
          href={link}
          target="_blank"
          component={Link}
          rel="noopener noreferrer"
        >
          {renderSocialIcon(name)}
        </IconButton>
      ) : (
        <></>
      )}
    </>
  );
};

export default SocialLink;
