import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { Box, Divider, Typography, Link, Stack } from '@mui/material';
import {
  Bonuses,
  LanguageButton,
  LoginButton,
  Location,
} from 'components/modals/NavModal/Drawer';
import { Portal, SocialLinks } from 'components/generic';
import { animated, useSpring } from 'react-spring';
import { useDelayUnmount, useDisableBodyScroll } from 'hooks';
import { NavLink, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { MenuTab } from 'types/MenuTabs';
import { useDrag } from '@use-gesture/react';
import { useTranslation } from 'react-i18next';
import { selectFirstAvailableRestaurant } from 'store/selectors/restaurantSelectors';
import { selectIsBrands } from 'store/selectors/brandsSelectors';
import { selectPageData } from 'store/selectors/pageSelectors';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthModalOpen: () => void;
  onSelectCityModalOpen: () => void;
  onLanguageModalOpen: () => void;
  handleBrandsModalOpen: () => void;
  menuTabs: MenuTab[];
}

const Drawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  onAuthModalOpen,
  onSelectCityModalOpen,
  onLanguageModalOpen,
  handleBrandsModalOpen,
  menuTabs,
}) => {
  useDisableBodyScroll(isOpen);
  const location = useLocation();
  const { LOGO_LIGHT } = useAppSelector(selectAppSettingsData);
  const isMount = useDelayUnmount(isOpen, 500);
  const { phone } = useAppSelector(selectFirstAvailableRestaurant);
  const isBrands = useAppSelector(selectIsBrands);
  const { t, i18n } = useTranslation();
  const xValue = i18n.dir() === 'ltr' ? -350 : 350;
  const aboutPageData = useAppSelector(selectPageData('about'));

  const paddingSocialValue =
    i18n.dir() === 'ltr' ? '30px 0 30px 66px' : '30px 66px 30px 0';

  const [{ x, opacity }, api] = useSpring(() => ({
    x: xValue,
    opacity: 0,
  }));
  const open = () => {
    api.start({
      x: 0,
      opacity: 1,
      immediate: false,
    });
  };
  const close = () => {
    api.start({
      x: xValue,
      opacity: 0,
      immediate: false,
    });
    if (isOpen) {
      onClose();
    }
  };
  const bind = useDrag(
    ({ last, velocity: [vx], direction: [dx], movement: [mx], cancel }) => {
      if (i18n.dir() === 'ltr') {
        if (mx > 40) cancel();
        if (last) {
          if (mx < -70 || (vx < 0.5 && dx < 0)) {
            close();
          } else {
            open();
          }
        } else api.start({ x: mx, immediate: true });
      } else {
        if (mx < -40) cancel();
        if (last) {
          if (mx > 70 || (vx < 0.5 && dx > 0)) {
            close();
          } else {
            open();
          }
        } else api.start({ x: mx, immediate: true });
      }
    },
    {
      axis: 'x',
      from: () => [0, x.get()],
      filterTaps: true,
      bounds: {
        right: 30,
      },
      rubberband: true,
    }
  );

  const handleLinkClick = useCallback(() => {
    window.open(
      process.env.REACT_APP_CARD_LINK,
      '_blank',
      'noopener,noreferrer'
    );
  }, [location]);

  const isCardLink = useMemo(() => {
    return process.env.REACT_APP_CARD_LINK;
  }, []);

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen]);

  return (
    <Portal>
      {isMount && (
        <Box>
          <animated.div
            onClick={close}
            style={{
              position: 'fixed',
              top: '0px',
              inset: '-1px',
              zIndex: '0',
              height: '150vh',
              background: 'rgba(0, 0, 0, 0.64)',
              opacity,
            }}
          />
          <animated.div
            {...bind()}
            style={{
              position: 'fixed',
              top: '0px',
              left: '-50px',
              right: '-50px',
              width: '330px',
              height: '150vh',
              backgroundColor: '#ffffff',
              touchAction: 'none',
              x,
            }}
          >
            <Box sx={{ padding: '20px 16px 20px 66px' }} onClick={close}>
              <Box sx={{ marginBottom: '20px', textAlign: 'center' }}>
                <Box
                  sx={{ WebkitTapHighlightColor: 'transparent' }}
                  component={NavLink}
                  to="/"
                >
                  <Box component="img" src={LOGO_LIGHT} />
                </Box>
              </Box>
              <Location onSelectCityModalOpen={onSelectCityModalOpen} />
              <Divider />
              <Box sx={{ padding: '30px 30px 50px 30px' }}>
                <Stack spacing={2.5} onClick={close}>
                  {isBrands && (
                    <Typography
                      sx={{
                        display: 'block',
                        fontSize: '16px',
                        textDecoration: 'none',
                        outline: 'none',
                        WebkitTapHighlightColor: 'transparent',
                        cursor: 'pointer',
                      }}
                      onClick={handleBrandsModalOpen}
                    >
                      {t('restaurants')}
                    </Typography>
                  )}
                  {menuTabs.map((tab: MenuTab) => (
                    <Typography
                      sx={{
                        display: 'block',
                        fontSize: '16px',
                        fontWeight:
                          location.pathname === tab.path ? '600' : '400',
                        color:
                          location.pathname === tab.path
                            ? 'primary.main'
                            : 'grey.50',
                        textDecoration: 'none',
                        outline: 'none',
                        WebkitTapHighlightColor: 'transparent',
                      }}
                      key={tab.label}
                      to={tab.path}
                      component={NavLink}
                    >
                      {t(`${tab.label}`)}
                    </Typography>
                  ))}
                  {isCardLink && (
                    <Typography
                      sx={{
                        display: 'block',
                        fontSize: '16px',
                        textDecoration: 'none',
                        outline: 'none',
                        WebkitTapHighlightColor: 'transparent',
                        cursor: 'pointer',
                      }}
                      onClick={handleLinkClick}
                    >
                      Карта клиента
                    </Typography>
                  )}
                </Stack>
              </Box>

              <Stack direction="column" spacing={2}>
                <LoginButton handleOpenAuthDrawer={onAuthModalOpen} />
                <Bonuses />
                <LanguageButton
                  handleOpenLanguageDrawer={onLanguageModalOpen}
                />
              </Stack>
            </Box>
          </animated.div>
          <animated.div
            {...bind()}
            style={{
              position: 'fixed',
              bottom: '0px',
              left: '-50px',
              right: '-50px',
              width: '330px',
              padding: `${paddingSocialValue}`,
              backgroundColor: '#ffffff',
              display: 'flex',
              flexDirection: 'column',
              x,
            }}
          >
            {aboutPageData?.is_contacts && phone && (
              <Typography
                sx={{
                  display: 'inline-block',
                  fontSize: '16px',
                  lineHeight: '19px',
                  textDecoration: 'none',
                  marginBottom: '8px',
                  WebkitTapHighlightColor: 'transparent',
                  color: 'text.primary',
                }}
                component={Link}
                href={`tel:${phone}`}
              >
                {phone}
              </Typography>
            )}

            <Box sx={{ marginLeft: '-8px' }}>
              <SocialLinks />
            </Box>
          </animated.div>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;
