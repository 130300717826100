import { useMemo } from 'react';
import { selectServerTime } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { City } from 'types/City';
import {
  selectLocationCity,
  selectTimeZone,
} from 'store/selectors/citySelectors';
import moment from 'moment-timezone';

const useWorkTime = () => {
  const serverTime = useAppSelector<string>(selectServerTime);
  const selectedCity = useAppSelector<City>(selectLocationCity);
  const timeZone = useAppSelector<string>(selectTimeZone);

  const currentTime = useMemo(() => {
    if (serverTime) {
      return moment.tz(serverTime, timeZone).format('HH:mm');
    }
    return moment();
  }, [serverTime]);

  const timeStart = useMemo(() => {
    if (selectedCity) {
      return moment(selectedCity.delivery_start, 'HH:mm').format('HH:mm');
    }
    return moment();
  }, [selectedCity]);

  const timeEnd = useMemo(() => {
    if (selectedCity) {
      return moment(selectedCity.delivery_end, 'HH:mm').format('HH:mm');
    }
    return moment();
  }, [selectedCity]);

  const isWorkTime = useMemo(() => {
    if (currentTime >= timeStart && currentTime <= timeEnd) {
      return true;
    }
    return false;
  }, [currentTime, timeStart, timeEnd]);

  return isWorkTime;
};

export default useWorkTime;
