import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTimeList } from 'hooks';
import { DatePicker, SelectField } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { selectServerTime } from 'store/selectors/appSelectors';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { selectTimeZone } from 'store/selectors/citySelectors';
import moment from 'moment-timezone';

interface DateTimePickerProps {
  type: 'delivery' | 'pickup';
}

const DateTimePicker: FC<DateTimePickerProps> = ({ type }) => {
  const dispatch = useAppDispatch();
  const { addPickupTime } = orderSlice.actions;
  const { control, watch } = useFormContext();
  const dayValue = watch('day');
  const timeValue = watch('time');
  const [updateTimeList, setUpdateTimeList] = useState<boolean>(false);
  const serverTime = useAppSelector(selectServerTime);
  const { availableTimeList, minDate } = useTimeList(
    dayValue?.$d,
    updateTimeList
  );
  const timeZone = useAppSelector<string>(selectTimeZone);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setUpdateTimeList(!updateTimeList);
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const time = moment(timeValue, 'HH:mm');
    const momentObj = moment(dayValue?.$d);
    const date = momentObj
      .tz(timeZone)
      .minutes(time.get('minutes'))
      .hours(time.get('hours'))
      .format();

    if (dayValue) {
      dispatch(addPickupTime(date));
    }
  }, [dayValue, timeValue]);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ marginBottom: { xs: '13px', sm: '30px' }, width: '100%' }}
      spacing={{ xs: 2, sm: 1.6 }}
    >
      <DatePicker
        control={control}
        name="day"
        label={type === 'delivery' ? t('delivery_date') : t('day')}
        minDate={minDate}
        defaultValue={new Date(serverTime)}
        required
      />

      <SelectField
        control={control}
        name="time"
        label={type === 'delivery' ? t('delivery_time_slot') : t('time')}
        required
        selectItem={availableTimeList}
        defaultValue=""
      />
    </Stack>
  );
};

export default DateTimePicker;
