import React, { FC } from 'react';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { PromoProduct } from 'types/Promotion';
import { Product } from 'components/modals/PromoProductsModal/Modal';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  promoProducts: PromoProduct[];
  actionId: number;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  promoProducts,
  actionId,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '30px',
          maxWidth: '940px',
          maxHeight: '676px',
          overflowY: 'inherit',
        },
      }}
    >
      <Box
        sx={{
          padding: '30px',
          paddingRight: '23px',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
          onClick={onClose}
          size="large"
          aria-label="close-btn"
        >
          <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
        </IconButton>
        <Typography
          sx={{ marginBottom: '20px', fontSize: '28px', fontWeight: '600' }}
        >
          {t('choose_a_gift')}
        </Typography>
        <Stack direction="column" spacing={2}>
          {promoProducts.map((product) => (
            <Product
              key={product.id}
              itemOption={product.item_option}
              actionId={actionId}
              onClose={onClose}
              title={product.item_obj?.name}
              description={product.item_obj?.description}
              src={product.item_obj?.src}
            />
          ))}
        </Stack>
      </Box>
    </Dialog>
  );
};

export default Modal;
